










import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PageNotFound extends Vue {
  get url (): string {
    return this.$route.path
  }

  get xs (): boolean { return this.$vuetify.breakpoint.xs }
}
